<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">
      统计设置
      <!-- <div class="hk_page_title_right">
                <el-button type="primary" size="medium" plain @click="ifShowAdd=true">添加账号</el-button>
            </div> -->
    </div>
    <div class="hk_page_wrap">功能开发中~</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>